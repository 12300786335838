.wholeform{
    border-radius: 20px;
    background-color: #e3ae0e;
}

.cont-frm{
   background-color: #FFDF00;
}

.alert {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
}

@media (min-width: 992px){

   .wholeform{
    width: 50% !important;
    }
}

@media (max-width: 992px){
    h1{
        font-size: 23px !important;
    }
    h2{
        font-size: 16px !important;
    }
    h3{
        font-size: 14px !important;
    }
 }
  