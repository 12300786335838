/* src/assets/css/reviews.css */

.reviews {
  padding: 50px 20px;
  background-color: #FFDF00;
  background-size: cover;
  text-align: center;
  color: #fff;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #fff;
}

.review-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 90%;
}

.review:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.review-avatar {
  margin-right: 15px;
}

.review-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-style: solid;
  border-color: black;
  border-width: 2px;
}

.review-content {
  text-align: left;
}

.review-author {
  font-size: 1.09rem;
  font-weight: bold;
  font-family: 'Poppins';
  margin: 0;
}

.review-comment {
  font-size: 0.9rem;
  color: black;
  font-family: 'Poppins';
  margin-bottom: 10px;
}

.review-rating {
  font-size: 1rem;
  color: gold;
}

.bi {
  font-size: 1.25rem;
  color: gold;
}

.add-review-btn{
  position: absolute;
  top: 85%;
  right: 2%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.more-reviews, .less-reviews{
  font-size: 18px !important;
  text-decoration: underline !important;
  color: #000 !important;
}

.more-reviews:hover, .less-reviews:hover {
  color: #555;
  cursor: pointer;
}

.add-review-btn:hover{
  background-color: #0056b3;
}
