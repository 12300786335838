/* App.css */

h1, h2, h3 {
  color: #333;
}

ul {
  padding: 0;
}

ul li {
  background: #f9f9f9;
  margin: 1.5rem 0;
  padding: 1rem;
  border: 1px solid #ddd;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
}

body {
  display: flex;
  flex-direction: column;
}

.scrolling-text-container {
  background-color: #e1c826;
  color: #000000;
  overflow: hidden;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.scrolling-text {
  white-space: nowrap;
  animation: scroll-left 1s linear infinite;
  animation-play-state: running; /* Start the animation */
}

.main {
  flex: 1;
}

.footer {
  background-color: #343a40;
  color: white;
  padding: 20px 0;
}
