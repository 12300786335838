.footer {
    padding: 20px 0;
  }
  
  .footer h5 {
    font-weight: bold;
    margin-bottom: 15px;
    color:#ffdd57;
  }
  
  .footer p {
    font-size: 14px;
  }

  
  .footer a:hover {
    text-decoration: none;
    color: #fff;
  }

.font{
  font-size: 27px;
}

.font1{
  font-size: 20px;
}

.hr1{
  border-style: solid;
  border-width: 2px;
  border-color: #fff !important;
  width: 95% !important;
}

@media (max-width:991px){
  .hr1{
    width: 100% !important;
  }
}
  