.whole-container {
  height: 70vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'arial';
}

.slider {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: visible;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}

.item {
  position: absolute;
  width: 30rem;
  height: 14rem;
  background-color: #f08048;
  background-size: cover;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.5s ease, opacity 0.5s ease, z-index 0s;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.slider .item {
  transition: transform 0.5s ease, opacity 0.5s ease, filter 0.5s ease;
}


#next, #prev {
  position: absolute;
  top: 50%;
  color: #fff;
  font-size: 1.1rem;
  font-family: monospace;
  font-weight: bold;
  border-radius: 50%;
  background-color:#f08048;
  border-style: none;
  height: 40px;
  width: 40px;
  cursor: pointer;
  text-align: center;
  transform: translateY(-90%);
}

#next {
  right: 50px;
}

#prev {
  left: 50px;
}

#next:hover, #prev:hover {
  color: #000;
}

.home-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.home-author {
  font-size: 1.09rem;
  font-weight: bold;
  margin: 0;
}

.home-comment {
  font-size: 15px !important;
  color: black;
  margin-bottom: auto;
  margin-top: auto;
  text-align: left;
}

.hr-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  margin-right: 15px;
}

.home-rating {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.home-rating i {
  color: gold;
}


@media (max-width:991px){
  .item{
    width: 12rem;
    height: 18rem;
    background-size: cover;
  }

  #next{
    right: 10px;
  }

  #prev{
    left: 10px;
  }

  .home-comment{
    font-size: 13px !important;
  }

}
