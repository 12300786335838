/* Ensure smooth transition for navbar */
.custom-navbar {
  background-color: #FFD700; /* Goldish-yellow background */
  transition: top 0.3s;
  z-index: 1000; /* Ensure navbar is above other content */
}

/* Fixed position when scrolled */
.navbar-scrolled {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(52, 58, 64, 0.9); /* Slightly transparent background */
  transition: background-color 0.3s ease-in-out, top 0.3s ease-in-out;
}


.dropdown-menu{
  background-color: #FFD700 !important;
}

/* Navbar links and brand color */
.custom-navbar .nav-link,
.custom-navbar .navbar-brand,
.custom-navbar .nav-dropdown {
  color: #000 !important; /* Black text color */
}

/* Navbar links and brand color on hover */
.custom-navbar .nav-link:hover,
.custom-navbar .navbar-brand:hover,
.custom-navbar .nav-dropdown:hover {
  background-color: #ff9e1f; /* Black text color on hover */
}

/* Dropdown menu item hover and focus state */
.navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item:hover,
.navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item:focus {
  background-color: #ff9e1f;
  color: black;
}

.head-nav{
  font-size: 20px;
  font-weight: 700;
  word-spacing: 1px;
}

.nav-img{
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

@media (max-width: 992px){
  .head-nav{
    font-weight: normal;
  }
}
