.home-container {
  background-size: cover;
  background-position: center;
  height: 90vh;
  background-image: url('../images/homebg1.jpg.png');
  padding-bottom: 30px;
}

#innerbg{
  width:100%;
  height: 100%;
  background-color:rgba(0, 0, 0, 0.3);
}

.space{
  height: 20vh;
}

.space2{
  height: 20vh;
}

.content {
  text-align: center;
  color: #fff;
  z-index: 1;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  animation: popUp 1s ease-out;
}

.contentblw {
  text-align: center;
  color: #FFDF00 !important;
  z-index: 1;
  margin: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 1.0);
  border-radius: 8px;
  animation: popUp 1s ease-out;
  width: 60%;
}

.blw {
  font-size: 20px;
  color: #FFDF00;
}

.bgreview{
  background-color: #fff !important;
}

@keyframes popUp {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.content h1 {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 20px;
}

.content p {
  font-size: 1rem;
}

html,
body {
  margin: 0;
  padding: 0;
}

.additional-section {
  background-color: #f0f0f0;
  text-align: center;
}

.additional-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.additional-section p {
  font-size: 1.10rem;
}

.buttn{
  animation: highlightAnimation 3s infinite;
  font-size: 20px;
  width: 150px;
  height: 50px;
  background-color: #FFDF00;
  color: #000;
}

#services{
  background-color: #fff !important;
}

.services{
  margin-top: 100px;
}

.service-card .card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

/* .service-card:hover .card {
  
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
} */ 

.service-card-link {
  text-decoration: none; 
  color: inherit; 
}

.service-card-link:hover,
.service-card-link:focus,
.service-card-link:active {
  text-decoration: none; 
}

@keyframes highlightAnimation {
  0% {
    opacity: 0.8;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.8;
    transform: scale(0.95);
  }
}

.highlight {
  animation: highlightAnimation 5s infinite;
  /* background-color: rgba(0, 0, 0, 0.5);
  box-shadow: rgba(0, 0, 0, 0.5); */
  padding: 0px;
}



.services-complete{
  background-color: #FFDF00;
  border-radius: 10px;
  width: 100%;
}  

.card{
  border-width: 0px;
  background-color: transparent;
}

.card-title{
  color: #000;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Poppins";
  margin-top: 10px;
}

.bgh{
  border-radius: 150px;
  width: 50%;
}

.bgcmn :hover{
  transform: translateY(-15px) rotate(0deg);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
}

.bgv{
  border-radius: 150px;
  width: 50%;
}

.bgn{
  /* background-image: url('../images/numerology2.png'); */
  border-radius: 150px;
  width: 50%;
}

.bgbu{
  border-radius: 150px;
  width: 50%;
}

.bgmd{
  border-radius: 150px;
  width: 50%;
}

.bgmarriage{
  border-radius: 150px;
  width: 50%;
}

.card-p{
  color: #ed790c;
  font-size: 1.25rem;
}

.about-us-home {
  width: 100%;
  overflow: hidden;
  background-color: #fff !important; 
}

.about-us-title{
  font-size: 2.0rem;
  font-family: "Poppins";
  font-weight: 400;
  text-decoration: underline;
}

.section-title {
  font-size: 2.0rem !important;
  margin-bottom: 30px;
  color: #000000 !important;
  font-family: "Poppins";
  text-align: left;
}

.about-image {
  width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.para {
  font-size: 16px;
  color: #000000;
  font-family: "Poppins";
  text-align: left;
}

.vedic {
  background-color: #3a88d6;
}

.vedic-img {
  width: 100% !important;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

@media (max-width:991px){
 
  .card-title{
    font-size: 13px;
  }

  .services-complete{
    border-radius: 0px;
  }

  .about-image, .vedic-img{
    margin-bottom: 15px;
    height: 18rem !important;
  }

  .home-container{
    height: 35rem;
  }

  .content h1{
    font-size: 30px;
  }

  .buttn{
    height: 40px;
    width: 150px;
    font-size: 20px;
  }

  .section-title{
    text-align: start;
    font-size: 26px !important;
    margin-left: 0px !important;
    margin-bottom: 8px !important;
  }

  .para{
    text-align: start;
    font-size: 15px !important;
    margin-left: 0px !important;
  }

}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  font-size: 35px;
  Height: 50px;
  width: 50px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensures it stays on top */
  text-align: center;
}

.whatsapp-button:hover {
  background-color: #128c7e;
  color: white;
}


@media (min-width:992px){

  .vedic-img, .about-image{
    height: 20rem;
  }

}