.title{
    font-size: 30px;
    font-family: "Poppins";
    font-weight: 500;
}

.para{
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 300;
}

.tp-container{
    padding: 20px;
    width: 100%;
}

.all-img{
    width: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.all-img-left{
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-left: 0px;
}

.desc{
    background-color: #FFDF00;
    border-radius: 20px;
    width: 100%;
}

.desc2{
    background-color: #FFDF00;
    border-radius: 20px;
    width: 100%;
}

@media (max-width:991px){
    .all-img, .all-img-left{
        border-bottom-right-radius: 0px;
        border-top-right-radius: 20px; 
        border-top-left-radius: 20px;  
        border-bottom-left-radius: 0px;
    }
}
  
  