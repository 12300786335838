.about-us {
  padding: 50px 0;
  overflow: hidden;
  background-color: #FFDF00 !important; 
}

.section-title {
  font-size: 2.4rem;
  margin-bottom: 30px;
  color: #000000 !important;
  font-family: "Poppins";
  text-align: left;
}

.about-image {
  width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.para {
  font-size: 16px;
  color: #000000;
  font-family: "Poppins";
  text-align: justify;
}

.vedic {
  background-color: #3a88d6;
}

.vedic-img {
  width: 100%;
  height: 55vh;
  display: block;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

hr {
  border-style: solid;
  border-width: 2px;
  border-color: #000000;
  opacity: 1;
  border-radius: 10px;
  width: 100%;
  margin-top: 50px ;
  margin-bottom: 50px;
}


.success {
  font-size: 50px;
  font-weight: bold;
  color: black;
  font-family: 'Poppins';
}

.ssp{
  font-size: 30px;
  font-weight: normal;
  font-family: 'Poppins';
}


.vrl{
  width: 1px;
  height: 60px;
  border-style: solid;
  border-width: 1px;
}

.cmnt{
  font-size: 23px;
  font-weight: bold;
  font-family: 'Poppins';
}

.map-container iframe {
  border: 0;
  width: 100%;
  height: 450px;
}

@media (max-width:991px) {

  .success{
    font-size: 30px;
  }

  .ssp{
    font-size: 25px;
  }

  .cmnt{
   font-size: 0.95rem;
   font-weight: 600;
  }

  hr{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .map-container iframe {
    width: 100%;
  }
}

