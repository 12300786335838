body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
  }
  
  
  .section {
    margin-bottom: 20px;
  }
  
  
  .services-list {
    list-style-type: none;
    padding: 0;
  }
  
  .services-list li {
    margin-bottom: 10px;
    color: black;
  }
  